import * as React from "react";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { AuthenticationTarget } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";

export interface GetAuthenticationTargetResponse {
    authenticationTargets: Array<AuthenticationTarget>;
}

export const useAuthTargets = () => {
    const [response, setResponse] = React.useState<AuthenticationTarget[] | null>(null);
    const load = React.useCallback(async () => {
        generalStore.setIsLoading(true);
        try {
            const res = await API.getAuthTargets();
            setResponse(res.authenticationTargets ?? []);

            console.log("response", res);
        } catch (error) {
            generalStore.setError(t("error.loadAuthTargets"), error);
        } finally {
            generalStore.setIsLoading(false);
        }
    }, []);
    React.useEffect(() => {
        load();
    }, [load]);
    return response;
};
