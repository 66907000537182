import { LoadingButton } from "@mui/lab";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { t } from "../../../../../i18n/util";
import { Distributor, GetCMSCustomersResponse } from "../../../../../network/APITypes";
import { CustomDrawer } from "../../../../ui/CustomDrawer";
import { CustomRadioGroup } from "../../../../ui/CustomRadioGroup";
import { DialogHeader } from "../../../../ui/DialogHeader";
import { SearchField } from "../../../../ui/SearchField";

const ALL_CUSTOMERS_LIST_LIMIT = 15;

export function IdsAddCustomerNumberDrawer(props: {
    allCustomers: GetCMSCustomersResponse | null; // Adjust the type accordingly based on your API response
    handleChangeSearch: (value: string) => void;
    handleEnter: (value: string, event?: React.KeyboardEvent<HTMLInputElement>) => void;
    handleSubmit: (
        setSubmitting: (isSubmitting: boolean) => void,
        values: { customerId: string },
        alternativeIDSMapping: boolean,
    ) => void;
    search?: string;
    setShowAddIdsForm: (show: boolean) => void;
    showAddIdsForm: boolean;
    getVkoShortName: (id?: string) => string | undefined;
}) {
    return (
        <CustomDrawer open={props.showAddIdsForm} onClose={() => props.setShowAddIdsForm(false)}>
            <Formik
                initialValues={{ customerId: "", distributorId: "" }}
                onSubmit={(model) => console.log(model)}
                validationSchema={Yup.object({
                    customerId: Yup.string().required(t("validationError.customerNumber")),
                })}
            >
                {({ isSubmitting, setSubmitting, values }) => (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            justifyContent: "space-between",
                        }}
                    >
                        <DialogHeader
                            onClose={() => props.setShowAddIdsForm(false)}
                            title={t("screen.customers.title")}
                        />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flexGrow: 1,
                                overflow: "hidden",
                            }}
                        >
                            <SearchField
                                onChange={props.handleChangeSearch}
                                onEnter={props.handleEnter}
                                placeholder={t("search.placeholder.customerNumberOrName")}
                                style={{
                                    marginBottom: 8,
                                    width: "100%",
                                }}
                                value={props.search}
                            />
                            <div style={{ flexGrow: 1, overflow: "auto" }}>
                                <Field
                                    name="customerId"
                                    component={CustomRadioGroup}
                                    options={
                                        props.allCustomers?.customers?.map((customer) => {
                                            const vkoShortName = props.getVkoShortName(customer.distributorId);

                                            return {
                                                label: (
                                                    <div
                                                        className="body1"
                                                        style={{
                                                            alignItems: "center",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            gap: "2px",
                                                            marginTop: 32,
                                                            marginBottom: 32,
                                                        }}
                                                    >
                                                        <span>{customer.externalId}</span>
                                                        <span
                                                            style={{
                                                                fontSize: 10,
                                                            }}
                                                        >
                                                            ({customer.name}
                                                            {vkoShortName ? `, ${vkoShortName}` : ""})
                                                        </span>
                                                    </div>
                                                ),
                                                value: customer.id,
                                            };
                                        }) ?? []
                                    }
                                    style={{ marginTop: 8, gap: 32 }}
                                    showValidationError={false}
                                />
                                <p
                                    style={{
                                        margin: "16px 0px",
                                        textAlign: "center",
                                        width: "full",
                                    }}
                                >
                                    {props.allCustomers &&
                                        props.allCustomers.total > ALL_CUSTOMERS_LIST_LIMIT &&
                                        `1 - ${ALL_CUSTOMERS_LIST_LIMIT} of ${props.allCustomers.total}`}
                                </p>
                            </div>
                        </div>

                        <LoadingButton
                            color="primary"
                            disabled={!values.customerId}
                            loading={isSubmitting}
                            onClick={() => props.handleSubmit(setSubmitting, values, true)}
                            variant="contained"
                        >
                            Alternatives Mapping anlegen
                        </LoadingButton>
                    </div>
                )}
            </Formik>
        </CustomDrawer>
    );
}
