import { useState } from "react";
import { CorporatePositionSelection } from "../components/forms/shared/CorporatePositionSelection";
import { CustomDrawer } from "../components/ui/CustomDrawer";
import { useCoreDataCorporatePositions } from "./useCoreDataCorporatePositions";

export const useCoreDataCorporatePositionSelection = ({
    hideBackdrop,
    name,
    target,
}: {
    hideBackdrop?: boolean;
    name: string;
    target: string;
}) => {
    const [open, setOpen] = useState(false);
    const corporatePositions = useCoreDataCorporatePositions(target);

    const handleClose = () => {
        setOpen(false);
    };

    const component = open ? (
        <CustomDrawer open={open} onClose={handleClose} hideBackdrop={hideBackdrop}>
            <CorporatePositionSelection name={name} positions={corporatePositions ?? []} onClose={handleClose} />
        </CustomDrawer>
    ) : null;

    return {
        open() {
            setOpen(true);
        },
        component,
        options:
            corporatePositions
                ?.flatMap((category) => category.roles)
                .map((role) => ({ value: role.id, label: role.name })) ?? [],
    };
};
