import React from "react";
import { generalStore } from "../stores/GeneralStore";
import { API } from "../network/API";
import { UserPreferences } from "../network/APITypes";
import { t } from "../i18n/util";

export const useUserPreference = (userPreferencesId?: string) => {
    const [response, setResponse] = React.useState<UserPreferences | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const load = React.useCallback(async () => {
        if (!userPreferencesId) {
            setResponse(null);
            return;
        }

        try {
            generalStore.setIsLoading(true);
            setIsLoading(true);
            const response = await API.getUserPreference(userPreferencesId);
            setResponse(response);
        } catch (err) {
            generalStore.setError(t("error.loadUserPreference"), err);
        } finally {
            generalStore.setIsLoading(false);
            setIsLoading(false);
        }
    }, [userPreferencesId]);

    React.useEffect(() => {
        load();
    }, [load]);

    return { userPreference: response, userPreferenceIsLoading: isLoading };
};
