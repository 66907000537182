import { Button, Step, StepContent, StepLabel, Stepper } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import * as Yup from "yup";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { BaseCMSCustomer, PostUserRequestsPayloadV2 } from "../../../network/APITypes";
import { generalStore } from "../../../stores/GeneralStore";
import { FieldError } from "../../ui/FieldError";
import { Colors } from "../../util/Colors";
import { isValidPhoneNumber, normalizePhoneNumber } from "../../util/Helpers";
import { getUserPhoneAndEmailValidationSchema } from "../editUser/EditUserForm";
import { PartnerPortalCustomerNumberSelection } from "./AddPartnerPortalCustomerNumberSelection";
import { PartnerPortalUserDetailsFields } from "./PartnerPortalUserDetailsFields";
import { SelectRoleField } from "./SelectRoleField";

type AddUserFormProps = {
    onClose: () => void;
    onSubmit?: () => void | Promise<void>;
    step: number;
    setStep: Dispatch<SetStateAction<number>>;
};
type MultiFormStepConfig = {
    name: string;
    title?: string;
    step: string;
    validationSchema?: any;
    content?: React.ReactNode;
    autoOverflow?: boolean;
};

const getPartnerPortalUserValidationSchema = () => {
    const { email, phone } = getUserPhoneAndEmailValidationSchema();
    return Yup.object().shape({
        corporatePositionID: Yup.string().required(t("validationError.corporatePosition")),
        email,
        firstName: Yup.string().required(t("validationError.firstName")),
        lastName: Yup.string().required(t("validationError.lastName")),
        phone,
        salutation: Yup.string().required(t("validationError.title")),
    });
};

export const createPartnerPortalUser = async (model: PostUserRequestsPayloadV2, onUserCreated?: () => void) => {
    try {
        generalStore.setIsLoading(true);
        await API.postPartnerPortalUserRequest(model);
        generalStore.setSuccessMessage(t("success.addUser"));
    } catch (error) {
        generalStore.setError(t("error.addUser"), error);
    } finally {
        await onUserCreated?.();
        generalStore.setIsLoading(false);
        return;
    }
};

const getPages = ({
    onSelectCustomer,
}: {
    onSelectCustomer: (customer?: BaseCMSCustomer) => void;
}): MultiFormStepConfig[] => [
    {
        autoOverflow: true,
        content: <PartnerPortalCustomerNumberSelection onSelectCustomer={onSelectCustomer} />,
        name: "customer",
        step: t("addUserForm.step.customerNumber.description"),
        validationSchema: Yup.object().shape({
            customerID: Yup.string().required(t("validationError.customerNumber")),
        }),
    },
    {
        autoOverflow: true,
        content: <PartnerPortalUserDetailsFields />,
        name: "details",
        step: t("addUserForm.step.userData.description"),
        validationSchema: getPartnerPortalUserValidationSchema,
    },
    {
        autoOverflow: true,
        content: <SelectRoleField accountType={["partner"]} />,
        name: "role",
        step: t("screen.userDetails.select.role.label"),
        validationSchema: Yup.object().shape({
            roleId: Yup.string().required(t("validationError.role")),
        }),
    },
];

const initialValues: PostUserRequestsPayloadV2 = {
    accountType: "partner",
    authenticationTargetIdentifier: "PP",
    corporatePositionID: "",
    customerID: "",
    email: "",
    firstName: "",
    lastName: "",
    roleId: "",
    salutation: "",
};

export const AddPartnerPortalUserForm = observer(({ onClose, onSubmit, step, setStep }: AddUserFormProps) => {
    const [selectedCustomer, setSelectedCustomer] = useState<BaseCMSCustomer | undefined>();

    const handleClickNext = (model: PostUserRequestsPayloadV2, helpers: FormikHelpers<PostUserRequestsPayloadV2>) => {
        helpers.setTouched({});
        helpers.setSubmitting(false);

        setStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleSubmit = async (model: PostUserRequestsPayloadV2) => {
        const userData = cloneDeep(model);
        userData.phone = normalizePhoneNumber(userData.phone);
        if (!isValidPhoneNumber(userData.phone)) {
            // For removal -> don't send empty string
            userData.phone = undefined;
        }

        if (!userData.email) {
            // For removal -> don't send empty string
            userData.email = undefined;
        }
        await createPartnerPortalUser(userData, async () => {
            await onSubmit?.();
            onClose();
        });
    };

    const handleSelectCustomer = useCallback((customer?: BaseCMSCustomer) => {
        setSelectedCustomer(customer);
    }, []);

    const getOptionalStepTitle = (index: number) => {
        if (index === 0) {
            return selectedCustomer?.externalId;
        }
    };

    const pages = getPages({
        onSelectCustomer: handleSelectCustomer,
    });

    const { content, validationSchema } = pages[step];

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={step < pages.length - 1 ? handleClickNext : handleSubmit}
            validationSchema={validationSchema}
            validateOnChange
        >
            {({ errors, isSubmitting, touched }) => (
                <Form
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        overflow: "hidden",
                        height: "100%",
                    }}
                    noValidate
                >
                    <Stepper activeStep={step} orientation="vertical">
                        {pages.map((page, index) => (
                            <Step key={page.step}>
                                <StepLabel
                                    optional={
                                        index < step ? (
                                            <p style={{ color: Colors.GREY_500, fontSize: 14 }}>
                                                {getOptionalStepTitle(index)}
                                            </p>
                                        ) : null
                                    }
                                >
                                    {page.step}
                                </StepLabel>
                                <StepContent>{content}</StepContent>
                            </Step>
                        ))}
                    </Stepper>
                    {errors.customerID && step === 0 && touched.customerID && (
                        <FieldError>{errors.customerID}</FieldError>
                    )}
                    {step === pages.length - 1 && (
                        <Button
                            type="submit"
                            fullWidth
                            style={{ marginBottom: 16 }}
                            disabled={isSubmitting}
                            variant="contained"
                        >
                            {t("addUserForm.button.save")}
                        </Button>
                    )}
                    {step < pages.length - 1 && (
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={isSubmitting || (selectedCustomer && !selectedCustomer.optIn)}
                        >
                            {t("button.next")}
                        </Button>
                    )}
                </Form>
            )}
        </Formik>
    );
});
