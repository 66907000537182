import { Field } from "formik";
import { compact } from "lodash";
import { useCoreDataCorporatePositionSelection } from "../../../hooks/useCoreDataCorporatePositionSelection";
import { t } from "../../../i18n/util";
import { FieldDefinition, Option, Section } from "../../../types";
import { CustomInputField } from "../../ui/CustomInputField";
import { CustomPhoneInput } from "../../ui/CustomPhoneInput";
import { CustomSectionButton } from "../../ui/CustomSectionButton";
import { CustomSelect } from "../../ui/CustomSelect";

export const PartnerPortalUserDetailsFields = () => {
    const corporatePositionName = "corporatePositionID";
    const corporatePositionSelection = useCoreDataCorporatePositionSelection({
        name: corporatePositionName,
        target: "cms",
        hideBackdrop: true,
    });

    const titleOptions: Option[] = [
        { label: t("common.male.title"), value: "Herr" },
        { label: t("common.female.title"), value: "Frau" },
    ];

    const fields: FieldDefinition[] = compact([
        {
            component: CustomSelect,
            label: "screen.userDetails.edit.form.title",
            name: "salutation",
            options: titleOptions,
        },
        {
            label: "screen.userDetails.edit.form.firstName",
            name: "firstName",
        },
        {
            label: "screen.userDetails.edit.form.lastName",
            name: "lastName",
        },
        {
            component: CustomSectionButton,
            label: "screen.userDetails.edit.form.corporatePosition",
            name: corporatePositionName,
            options: corporatePositionSelection.options,
        },
        {
            label: "screen.userDetails.edit.form.email",
            name: "email",
            type: "email",
        },
        {
            component: CustomPhoneInput,
            label: "screen.userDetails.edit.form.phone",
            name: "phone",
            showHint: true,
        },
    ]);

    const handleClickSection = (section: Section | null) => {
        switch (section) {
            case corporatePositionName: {
                corporatePositionSelection.open();
                return;
            }
        }
    };

    return (
        <>
            {fields.map((field) => {
                return (
                    <Field
                        component={field.component ?? CustomInputField}
                        disabled={field.disabled}
                        key={field.name}
                        label={t(field.label)}
                        name={field.name}
                        onChange={field.onChange}
                        onClick={() => handleClickSection(field.name as Section)}
                        options={field.options}
                        required={field.required}
                        showHint={field.showHint}
                        type={field.type ?? "input"}
                    />
                );
            })}
            {corporatePositionSelection.component}
        </>
    );
};
