import { useCallback, useEffect, useState } from "react";
import { t } from "../i18n/util";
import { API } from "../network/API";
import { PostUserRequestExistsResponse } from "../network/APITypes";
import { generalStore } from "../stores/GeneralStore";

export const useUserRequestExists = (distributorId?: string, email?: string, phone?: string) => {
    const [response, setResponse] = useState<PostUserRequestExistsResponse | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const load = useCallback(async () => {
        if ((!email && !phone) || (!email && phone && phone?.length < 7)) {
            setResponse(null);
            return;
        }

        generalStore.setIsLoading(true);
        setIsLoading(true);
        try {
            const res = await API.postUserRequestExists({
                distributorId: distributorId === "" ? undefined : distributorId,
                email: email === "" ? undefined : email,
                phone: phone === "" ? undefined : phone,
            });
            setResponse(res);
        } catch (error) {
            generalStore.setError(t("error.loadCustomer"), error);
        } finally {
            generalStore.setIsLoading(false);
            setIsLoading(false);
        }
    }, [distributorId, email, phone]);

    useEffect(() => {
        load();
    }, [load]);

    return { response, email, phone, load, isLoading };
};
