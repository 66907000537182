import { Field } from "formik";
import { useRoles } from "../../../hooks/useRoles";
import { t } from "../../../i18n/util";
import { AccountType } from "../../../network/APITypes";
import { Option } from "../../../types";
import { CustomSelect } from "../../ui/CustomSelect";

export const SelectRoleField = (props: { accountType: AccountType[] }) => {
    const roles = useRoles({
        accountTypes: props.accountType,
    });

    const roleOptions: Option[] =
        roles.rolesResponse?.roles?.map((role) => {
            return {
                label: role.name,
                value: role.id,
            };
        }) ?? [];

    return (
        <Field
            component={CustomSelect}
            key="roleId"
            label={t("screen.userDetails.select.role.label")}
            name="roleId"
            options={roleOptions}
        />
    );
};
