import { Divider, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";
import { AddPartnerPortalUserForm } from "../components/forms/addUser/AddPartnerPortalUserForm";
import { AddUserForm } from "../components/forms/addUser/AddUserForm";
import { CustomDrawer } from "../components/ui/CustomDrawer";
import { DialogHeader } from "../components/ui/DialogHeader";
import { t } from "../i18n/util";
import { AccountType } from "../network/APITypes";

export const useAddUserForm = ({ onSubmit }: { onSubmit?: () => void | Promise<void> }) => {
    const [accountType, setAccountType] = useState<AccountType>("b2b-customer");
    const [open, setOpen] = useState<boolean>(false);
    const [step, setStep] = useState<number>(0);
    const accountTypes = ["b2b-customer", "employee", "manufacturer", "supplier", "partner"] as AccountType[];

    const handleClose = () => {
        setAccountType("b2b-customer");
        setOpen(false);
        setStep(0);
    };

    const getCustomerTypeText = (type: AccountType) => {
        switch (type) {
            case "employee":
                return t("accountType.employee");
            case "manufacturer":
                return t("accountType.manufacturer");
            case "partner":
                return t("accountType.partner");
            case "supplier":
                return t("accountType.supplier");
            default:
                return t("accountType.b2bCustomer");
        }
    };

    const component = (
        <CustomDrawer
            onClose={handleClose}
            open={open}
            style={{ display: "flex", flexDirection: "column", height: "100%", padding: "16px 12px" }}
        >
            <DialogHeader
                onBack={step > 0 ? () => setStep((prev) => prev - 1) : undefined}
                onClose={handleClose}
                title={t("common.accountType.select")}
            />
            <Divider />
            <FormControl disabled={step > 0}>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    onChange={(event) => setAccountType(event.target.value as AccountType)}
                    value={accountType}
                >
                    {accountTypes.map((accountType) => (
                        <FormControlLabel
                            control={<Radio />}
                            key={accountType}
                            label={<span style={{ fontSize: "0.8rem" }}>{getCustomerTypeText(accountType)}</span>}
                            value={accountType}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                }}
            >
                {accountType === "partner" ? (
                    <AddPartnerPortalUserForm onClose={handleClose} onSubmit={onSubmit} step={step} setStep={setStep} />
                ) : (
                    <AddUserForm
                        accountType={accountType}
                        key={accountType}
                        onClose={handleClose}
                        onSubmit={onSubmit}
                        step={step}
                        setStep={setStep}
                    />
                )}
            </div>
        </CustomDrawer>
    );

    return {
        open() {
            setOpen(true);
        },

        component,
    };
};
