import { IconButton } from "@mui/material";
import { Icon } from "../util/Icon";

export const DialogHeader = ({
    title,
    onBack,
    onClose,
    style,
}: {
    title: string;
    onBack?: () => void;
    onClose?: () => void;
    style?: React.CSSProperties;
}) => {
    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", ...style }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                {onBack && (
                    <IconButton onClick={onBack}>
                        <Icon name="arrowBack" />
                    </IconButton>
                )}
                <h4>{title}</h4>
            </div>
            {onClose ? (
                <IconButton onClick={onClose}>
                    <Icon name="close" />
                </IconButton>
            ) : null}
        </div>
    );
};
