import { LoadingButton } from "@mui/lab";
import { Button, Step, StepContent, StepLabel, Stepper, Tooltip } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react";
import * as React from "react";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import { useCustomerStatus } from "../../../hooks/useCustomerStatus";
import { useDistributors } from "../../../hooks/useDistributors";
import { useInvitationTypeSelection } from "../../../hooks/useInvitationTypeSelection";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import {
    AccountType,
    BaseCustomer,
    Distributor,
    PostCreateUserInvitePayloadInvitationTypeEnum,
    PostUserRequestsPayloadV2,
} from "../../../network/APITypes";
import { generalStore } from "../../../stores/GeneralStore";
import { FieldError } from "../../ui/FieldError";
import { LinkButton } from "../../ui/LinkButton";
import { Colors } from "../../util/Colors";
import { getUserDialogConfiguration } from "../../util/Dialogs";
import { getFormattedDate, isErrorOfType, isValidPhoneNumber, normalizePhoneNumber } from "../../util/Helpers";
import { userValidationSchema } from "../editUser/EditUserForm";
import { UserDetailsFields } from "../shared/UserDetailsFields";
import { CustomerNumberSelection } from "./CustomerNumberSelection";
import { SelectRoleField } from "./SelectRoleField";
import { VkoSelection } from "./VkoSelection";

type FormikPostUserRequestsPayload = Omit<PostUserRequestsPayloadV2, "accountType"> & { accountType?: string };

type MultiFormStepConfig = {
    autoOverflow?: boolean;
    content?: React.ReactNode;
    name: string;
    step: string;
    validationSchema?: any;
};

const InviteButton = (props: {
    children: string;
    values: FormikPostUserRequestsPayload;
    onClick: () => void;
    isSubmitting: boolean;
}) => {
    const { customerStatus, loading } = useCustomerStatus({
        customerId: props.values.customerID,
        distributionChannelId: props.values.distributionChannelID ?? "",
        distributorId: props.values.distributorID ?? "",
        regionId: props.values.regionID ?? "",
    });

    return (
        <Tooltip title={customerStatus === "locked" ? t("addUserForm.customerLocked.tooltip") : undefined}>
            <div>
                <LoadingButton
                    onClick={props.onClick}
                    fullWidth
                    variant="contained"
                    disabled={props.isSubmitting || customerStatus === "locked"}
                    loading={loading}
                >
                    {props.children}
                </LoadingButton>
            </div>
        </Tooltip>
    );
};

const getPagesB2BCustomer = (props: {
    accountType: AccountType | null;
    currentCustomer?: BaseCustomer;
    distributors: Distributor[] | null;
    onSelectCustomer: (customer?: BaseCustomer) => void;
}): MultiFormStepConfig[] => [
    {
        autoOverflow: true,
        content: <VkoSelection distributors={props.distributors} />,
        name: "vko",
        step: t("addUserForm.step.vko.description"),
        validationSchema: Yup.object().shape({
            distributorID: Yup.string().required(t("validationError.disbributor")),
        }),
    },
    ...getPages({ ...props }),
];

const getPages = (props: {
    accountType: AccountType | null;
    currentCustomer?: BaseCustomer;
    onSelectCustomer: (customer?: BaseCustomer) => void;
}): MultiFormStepConfig[] => [
    {
        autoOverflow: true,
        content: (
            <CustomerNumberSelection
                accountType={props.accountType}
                initialSearch={props.currentCustomer?.externalId}
                onSelectCustomer={props.onSelectCustomer}
            />
        ),
        name: "customer",
        step: t("addUserForm.step.customerNumber.description"),
        validationSchema: Yup.object().shape({
            customerID: Yup.string().required(t("validationError.customerNumber")),
        }),
    },
    {
        autoOverflow: true,
        content: <UserDetailsFields accountType={props.accountType ?? "b2b-customer"} />,
        name: "details",
        step: t("addUserForm.step.userData.description"),
        validationSchema: userValidationSchema(),
    },
    {
        autoOverflow: true,
        content: <SelectRoleField accountType={[props.accountType ?? "b2b-customer"]} />,
        name: "role",
        step: t("screen.userDetails.select.role.label"),
        validationSchema: Yup.object().shape({
            roleId: Yup.string().required(t("validationError.role")),
        }),
    },
];

const initialValues: FormikPostUserRequestsPayload = {
    accountType: "",
    corporatePositionID: "",
    customerID: "",
    distributionChannelID: "",
    distributorID: "",
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    regionID: "",
    roleId: "",
    salutation: "",
    userPreferencesId: "-1",
};

type AddUserFormProps = {
    accountType: AccountType;
    onClose: () => void;
    onSubmit?: () => void | Promise<void>;
    step: number;
    setStep: Dispatch<SetStateAction<number>>;
};

export const createUser = async (
    model: PostUserRequestsPayloadV2,
    inviteUser?: boolean,
    invitationType?: PostCreateUserInvitePayloadInvitationTypeEnum,
    onUserCreated?: () => void,
) => {
    try {
        generalStore.setIsLoading(true);
        const user = await API.postUserRequest(model as PostUserRequestsPayloadV2);

        if (inviteUser) {
            try {
                await API.postInviteUsers([user.id], undefined, invitationType);
                generalStore.setSuccessMessage(t("success.addUserAndInvite"));
            } catch (error) {
                generalStore.setError(t("error.addUser.invite"), error);
            } finally {
                await onUserCreated?.();
                generalStore.setIsLoading(false);
                return;
            }
        }

        generalStore.setSuccessMessage(t("success.addUser"));
    } catch (error) {
        if (isErrorOfType(error, "EMAIL_MISSING_FOR_PERMISSION_SET")) {
            inviteUser
                ? generalStore.setError(t("error.addUser.addAndInvite.emailMissing"), error)
                : generalStore.setError(t("error.addUser.add.emailMissing"), error);
        } else if (inviteUser) {
            generalStore.setError(t("error.addUser.addAndInvite"), error);
        } else {
            generalStore.setError(t("error.addUser"), error);
        }
    } finally {
        await onUserCreated?.();
        generalStore.setIsLoading(false);
    }
};

export const AddUserForm = observer(({ accountType, onClose, onSubmit, step, setStep }: AddUserFormProps) => {
    const [inviteUser, setInviteUser] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState<BaseCustomer | undefined>();
    const { distributors } = useDistributors();

    const handleClickNext = (
        model: FormikPostUserRequestsPayload,
        helpers: FormikHelpers<FormikPostUserRequestsPayload>,
    ) => {
        helpers.setTouched({});
        helpers.setSubmitting(false);

        setStep((prevActiveStep) => prevActiveStep + 1);
    };

    const [userPayload, setUserPayload] = useState<PostUserRequestsPayloadV2>();

    const inviteDialog = useInvitationTypeSelection({ hideBackdrop: true, onClose, userPayload });

    const handleSubmit = async (model: FormikPostUserRequestsPayload) => {
        const userData = cloneDeep(model);
        userData.phone = normalizePhoneNumber(userData.phone);
        userData.accountType = accountType;

        if (!isValidPhoneNumber(userData.phone)) {
            // For removal -> don't send empty string
            userData.phone = undefined;
        }

        if (!userData.email) {
            // For removal -> don't send empty string
            userData.email = undefined;
        }

        // If both email and phone are filled out, open invite type selection dialog
        const inviteSelection = inviteUser && userData.email && userData.phone;
        if (inviteSelection) {
            setUserPayload(userData as PostUserRequestsPayloadV2);
            inviteDialog.open();
            return;
        }

        // Select invitation type based on whether email or phone was provided
        let invitationType: PostCreateUserInvitePayloadInvitationTypeEnum | undefined;
        if (inviteUser) {
            invitationType = userData.email ? "email" : "sms";
        }

        //delete empty string -> backend handles preferences
        if (!userData.userPreferencesId || userData.userPreferencesId === "-1") {
            userData.userPreferencesId = undefined;
        }

        // Now create and invite
        await createUser(userData as PostUserRequestsPayloadV2, inviteUser, invitationType, async () => {
            await onSubmit?.();
            onClose();
        });
    };

    const handleSelectCustomer = useCallback((customer?: BaseCustomer) => {
        setSelectedCustomer(customer);
    }, []);

    const handleSubmitRequestUserManagementDialog = async () => {
        if (selectedCustomer) {
            try {
                generalStore.setIsLoading(true);
                await API.postCustomerRequestOptIn(selectedCustomer.id);
                generalStore.setSuccessMessage(t("common.success"));
                onClose();
            } catch (error) {
                generalStore.setError(t("error.customerOptIn"), error);
            } finally {
                generalStore.setIsLoading(false);
            }
        }
    };

    const userManagementDialog = useConfirmationDialog({
        ...getUserDialogConfiguration("requestUserManagement", { user: generalStore.user }),
        onSubmit: handleSubmitRequestUserManagementDialog,
    });

    const pages =
        accountType === "b2b-customer" || accountType === "employee"
            ? getPagesB2BCustomer({
                  accountType: accountType,
                  currentCustomer: selectedCustomer,
                  distributors: distributors,
                  onSelectCustomer: handleSelectCustomer,
              })
            : getPages({
                  accountType: accountType,
                  currentCustomer: selectedCustomer,
                  onSelectCustomer: handleSelectCustomer,
              });
    const { content, validationSchema, autoOverflow } = pages[step];

    useEffect(() => {
        initialValues.distributorID = distributors && distributors.length > 0 ? distributors[0].id : "";
    }, [accountType, distributors]);

    const getOptionalStepTitle = (index: number, values?: FormikPostUserRequestsPayload) => {
        if (index === 0) {
            return distributors?.find((distributor) => distributor.id === values?.distributorID)?.shortName;
        }
        if (index === 1) {
            return selectedCustomer?.externalId;
        }
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={step < pages.length - 1 ? handleClickNext : handleSubmit}
                validationSchema={validationSchema}
                validateOnChange
            >
                {({ errors, isSubmitting, submitForm, values, touched, isValid }) => (
                    <Form
                        style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            justifyContent: "space-between",
                            overflow: "hidden",
                        }}
                        noValidate
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                flexGrow: 1,
                                overflow: autoOverflow ? "auto" : "hidden",
                            }}
                        >
                            <Stepper activeStep={step} orientation="vertical">
                                {pages.map((page, index) => (
                                    <Step key={page.step}>
                                        <StepLabel
                                            optional={
                                                index < step ? (
                                                    <p style={{ color: Colors.GREY_500, fontSize: 14 }}>
                                                        {getOptionalStepTitle(index, values)}
                                                    </p>
                                                ) : null
                                            }
                                        >
                                            {page.step}
                                        </StepLabel>
                                        <StepContent>{content}</StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                        <div>
                            {selectedCustomer && !selectedCustomer.optIn && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 8,
                                        marginBottom: 32,
                                        marginTop: 4,
                                    }}
                                >
                                    <p className="body2" style={{ fontSize: 14 }}>
                                        {t("addUserForm.customer.requestUserManagement.title")}
                                    </p>
                                    <LinkButton
                                        color="primary"
                                        type="button"
                                        onClick={(event) => {
                                            event?.preventDefault();
                                            userManagementDialog.open();
                                        }}
                                        style={{ fontSize: 14 }}
                                    >
                                        {t("button.requestUserManagement")}
                                    </LinkButton>
                                    <p className="body2" style={{ fontSize: 14 }}>
                                        {selectedCustomer.optInLastRequestedAt
                                            ? t("common.lastRequested", {
                                                  date: getFormattedDate(selectedCustomer.optInLastRequestedAt),
                                              })
                                            : t("common.neverRequested")}
                                    </p>
                                </div>
                            )}
                            {errors.customerID && step === 1 && touched.customerID && (
                                <FieldError>{errors.customerID}</FieldError>
                            )}
                            {errors.distributorID && <FieldError>{errors.distributorID}</FieldError>}
                            {step < pages.length - 1 ? (
                                <Button
                                    disabled={isSubmitting || (selectedCustomer && !selectedCustomer.optIn) || !isValid}
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                >
                                    {t("button.next")}
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        disabled={isSubmitting}
                                        fullWidth
                                        onClick={() => {
                                            setInviteUser(false);
                                            submitForm();
                                        }}
                                        style={{ marginBottom: 16 }}
                                    >
                                        {t("addUserForm.button.save")}
                                    </Button>
                                    <InviteButton
                                        isSubmitting={isSubmitting}
                                        onClick={() => {
                                            setInviteUser(true);
                                            submitForm();
                                        }}
                                        values={values}
                                    >
                                        {t("addUserForm.button.saveAndInvite")}
                                    </InviteButton>
                                </>
                            )}
                        </div>
                        {userManagementDialog.component}
                    </Form>
                )}
            </Formik>
            {inviteDialog.component}
        </>
    );
});
